import React, { useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import breakDownAllNodes from "utils/breakDownAllNodes";
import Wrapper from "containers/Wrapper";
import Product from "containers/Product";
import { scroller } from "react-scroll";

export const query = graphql`
  query ProductQuery($langKey: String!) {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          imageFileName
          anchor
          sections {
            anchor
          }
          copyright
          social {
            linkedin
          }
          privacyText
          termsText
          
          titleTemplate
          keywords
          description
          
          serviceForm
          contentForm
          iconFileName
          title
          desc
          textButton
          bgColor
          textColor
          header
          subheader
          content
          items {
            imageFileName
            header
            content
          }
          
          labelName
          feedbackName
          labelEmail
          feedbackEmail
          labelMessage
          feedbackMsg
          descMessage
          buttonText
          textSuccess
          fetchUrl
          errorMsg
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const ProductPage = ({ location, pathContext, data }) => {
  const { allMarkdownRemark: { nodes } } = data;
  const { productNodes, formNode: { frontmatter: frontmatterForm } } = breakDownAllNodes(
    nodes);

  const currentProduct = location.pathname.slice(1, -1).split("/").pop();
  const productNode = productNodes.filter(
    ({ fields: { fileName } }) =>
      (fileName).indexOf(currentProduct) !== -1)[0];

  useEffect(() => {
    scroller.scrollTo("productDetails", {
      duration: 1500,
      delay: 0,
      offset: -53,
      smooth: "easeInOutQuart",
    });
  }, []);

  return (
    <Wrapper
      pathContext={pathContext}
      nodes={nodes}
      node={productNode}
    >
      <Product
        productNode={productNode}
        pathContext={pathContext}
        productNodes={productNodes}
        frontmatterForm={frontmatterForm}
      />
    </Wrapper>
  );
};

ProductPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

ProductPage.defaultProps = {
  location: {},
  pathContext: {
    langKey: "de",
    defaultLang: "de",
    langTextMap: {},
  },
};

export default ProductPage;
